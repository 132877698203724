import React from 'react'
import { Chart } from "react-google-charts"

function RSOTChart({ charttype, data, options, width, height, loadermsg }) {
    return (
        <Chart
            chartType={charttype}
            data={data}
            options={options}
            width={width}
            height={height}
            loader={loadermsg}
        />
    )
}

export default RSOTChart