import React from 'react'
import styled from 'styled-components'
import RSOTChart from '../components/piechart.js'

import Layout from '../layouts'
import SEO from '../components/seo'
import BG from '../components/bg'

const StyledTokenomics = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  padding: 0 2rem;
  padding-bottom: 4rem;
  margin-bottom: 4rem;
  padding-top: 2rem;

  border-bottom: 1px solid ${({ theme }) => theme.colors.grey2};

  @media (max-width: 960px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    margin-top: 0rem;
    padding-top: 1rem;
  }

  @media (max-width: 560px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const StyledSectionFlex = styled.div`
  padding: 0 0 4rem 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  // max-width: 960px;
  // margin-left: 5rem;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 1rem;
    margin-top: 0rem;
    // flex-direction: ${({ wrapSmall }) => (!wrapSmall ? 'row' : 'column')};
  }
  @media (max-width: 960px) {
    padding: 1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 560px) {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: 0;
    margin-top: 0rem;
    width: 100%;
    // flex-direction: column;
    justify-content: center;
  }
  h1,                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
  h2 {
    max-width: 650px;
  }
  p {
    /* margin-bottom: 0.5rem; */
    max-width: 650px;
  }
`

const StyledSectionTitle = styled.h3`
  max-width: 960px;
  line-height: 140%;
  font-size: 32px;
  @media (max-width: 640px) {
    text-align: left;
  }
`

const StyledBodyText = styled.div`
  font-size: 16px;
  margin-bottom: 1rem;
`

const StyledExternalLink = styled.a`
  margin: 0;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  :focus {
    outline: 0;
    opacity: 0.9;
  }
  :hover {
    * {
      color: ${({ theme }) => theme.colors.link};
    }
  }
`

const StyledCard = styled.div`
  background-color: ${({ theme }) => theme.cardBG};
  border: 1px solid ${({ theme }) => theme.buttonBorder};
  // padding: 2rem;
  margin: 0rem 1rem;
  border-radius: 24px;
  box-shadow: ${({ theme }) => theme.shadows.huge};
  margin-bottom: 2rem;
`

const Tokenomics = props => {

    const tkndata = [
        [`Tokenomics`, `Tokens`]
        , [`Private/Pre-Sales`, 29]
        , [`Team`, 26]
        , [`Community & Marketing Making`, 5]
        , [`Technology & Dev`, 26]
        , [`Expansion/Partnerships`, 14]
    ]

    const tknoptions = {
        title: "Tokenomics"
        , titleTextStyle: {
        fontSize: `26`
        }
        , is3D: true
        , legend: { 
        position: `top`
        , maxLines: 10
        }
        , backgroundColor: `transparent`
    }

    const funddata = [
        [`Use of Funds`, `Amount`]
        , [`Employee & Ops`, 38]
        , [`Business Dev`, 11]
        , [`Exchange Listings`, 6]
        , [`Legal & Community`, 8]
        , [`Research & Dev`, 20]
        , [`PR & Marketing`, 17]
    ]

    const fundoptions = {
        title: "Use of Funds"
        , titleTextStyle: {
        fontSize: `26`
        }
        , is3D: true
        , legend: { 
        position: `top`
        , maxLines: 10
        }
        , backgroundColor: `transparent`
    }

    return (
        <Layout path={props.location.pathname}>
          <BG />
    
          <SEO title="Tokenomics / Use of Funds" path={props.location.pathname} />
  
          <StyledTokenomics>
            <StyledSectionTitle>
              Tokenomics / Use of Funds
            </StyledSectionTitle>
            <StyledSectionFlex>
              <StyledCard style={{ width: `100%` }}>
                <RSOTChart charttype={'PieChart'} data={tkndata} options={tknoptions} width={`100%`} height={`500px`} loadermsg={`Loading Tokenomics`} />
              </StyledCard>
              <StyledCard style={{ width: `100%` }}>              
                <RSOTChart charttype={'PieChart'} data={funddata} options={fundoptions} width={`100%`} height={`500px`} loadermsg={`Loading Use of Funds`} />
              </StyledCard>
            </StyledSectionFlex>
            <StyledBodyText>
                It is essential to note that RSOT has a limited total supply, and at the time of writing, is still at positive emissions rate. At this point, RSOT is still an inflationary token, but the team has in place several strategies and mechanisms to influence token supply when needed and necessary. Currently, there are over 2.2RSOT/Block new RSOT tokens added to the supply daily.
            </StyledBodyText>
            <StyledBodyText>
                The current supply of the RSOT token can be tracked on our <StyledExternalLink href='https://app.rissotto.finance'>site</StyledExternalLink>. There is a current supply of 10 Million RSOT in circulation. There will be strategic token burns in <StyledExternalLink href='/roadmap'>due time</StyledExternalLink> to help offset the daily emission.
            </StyledBodyText>
            </StyledTokenomics>
        </Layout>
    )
}

export default Tokenomics